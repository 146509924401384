@import url("https://fonts.googleapis.com/css2?family=Source+Sans+3:ital,wght@0,200..900;1,200..900&display=swap");
/* Font need to be loaded locally */

body {
  margin: 0;
}

html, body, #root, .app, .content {
  height: 100%;
  scroll-behavior: smooth;
  overflow: auto;
  width: 100%;
  font-family: "Source Sans 3", 'Comic Sans';
}

.app {
  display: flex;
  position: relative;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Navbar List Emotion */
.css-ewdv3l {
  height: 100%;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e0e0e0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-track:hover {
  background-color: #555;
}

.MuiPaper-rounded {
  border-radius: 15px !important;
} 